import React from 'react'
import Header from './Header/Header'
import './Hero.css'
import hero_image from "../../src/assets/hero_image.png";
import hero_image_back from "../../src/assets/hero_image_back.png";
import Heart from "../../src/assets/heart.png";
import calories from "../../src/assets/calories.png";
const Hero = () => {
    return (
        <div className="hero">
          <div className="blur hero-blur"></div>

            <div className="left-h">
                <Header />
                {/* the best ad */}
                <div className="the-best-ad">
                    <div></div>
                    <span>the best fitness slub in the town</span>
                </div>
                {/* Hero heading*/}
                <div className="hero-text">
                    <div>
                        <span className="stroke-text"> Shape </span>
                        <span>Your</span>
                    </div>
                    <div>
                        <span>
                            Ideal body
                        </span>
                    </div>
                    <div>
                        <span>
                            In here we will help you to shape and build your ideal body and live up your life to
                            fullest
                        </span>
                    </div>
                </div>
                {/* figures*/}
                <div className="figures">
                    <div>
                        <span>+140</span>
                        <span>expert coachs</span>
                    </div>
                    <div>

                        <span>+978</span>
                        <span>members joined</span>
                    </div>
                    <div>
                        <span>+50</span>
                        <span>fitness programs</span>
                    </div>
                </div>
                {/* hero buttons */}
                <div className="hero-buttons">
                    <buttons className="btn"> Get started</buttons>
                    <buttons className="btn">Learn more</buttons>
                </div>


            </div>
            <div className="right-h">
                <button className='btn'>join now</button>
                <div className="heart-rate">
                    <img src={Heart} alt="" />
                    <span>Heart Rate</span>
                    <span>116 bpm</span>
                </div>

                {/* hero images*/}
                <img src={hero_image} alt="" className="hero-image" />
                <img src={hero_image_back} alt="" className='hero-image-back' />
                {/* calories*/}
                <div className="calories">
                    <img src={calories} alt="" />
                    <div>
                        <span>CaloriesBurned</span>
                        <span>220kcal</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero