import React, { useRef } from 'react'
import './Joints.css'
import emailjs from '@emailjs/browser'

const Joints = () => {
 const form  = useRef()
 
 const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, {
        publicKey: 'YOUR_PUBLIC_KEY',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };


  return (
    <div className="Joints" id="Joints-us">
        <div className="left-j">
            <div>
                <span className="stroke-text">READY TO</span>
                <span> LEVEL UP</span>
            </div>
            <div>
              <span>YOUR BODY</span>
              <span className="stroke-text"> WITH US?</span>
            </div>
        </div>
        <div className="right-j">
            <form action="" className="email-container" onSubmit={sendEmail}>
                <input type="email"  name="Username" placeholder="Enter your email address"/>
                <button className=" btn btn-j">Join Now</button>
            </form>
        </div>
    </div>
  )
}

export default Joints